"use client";

import { NavigationitemComponent } from "movestic-blocks/components/__generated__/graphql";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "movestic-core/tailwind/Accordion";
import { Chevron } from "movestic-core/tailwind/icons/Chevron";
import { Search } from "movestic-core/tailwind/icons/Search";
import { Sheet, SheetContent, SheetTrigger } from "movestic-core/tailwind/Sheet";
import { useState } from "react";
import { Lock } from "movestic-core/tailwind/icons/Lock";
import { getLink } from "movestic-blocks/utils/cms/storyblokHelpers";
import { H4 } from "movestic-core/tailwind/Texts";
import Link from "next/link";
import { ArrowRight } from "movestic-core/tailwind/icons/ArrowRight";

export default function MobileNav({
    navigation,
    showLoginButton,
}: {
    navigation: NavigationitemComponent[];
    showLoginButton?: boolean;
}) {
    const listItemStyle = "px-4 items-center flex flex-col gap-1 h-full justify-center ";
    const [linkMenuOpen, setLinkMenuOpen] = useState<string | undefined | false>(false);
    const [open, setOpen] = useState(false);

    return (
        <ol className="flex flex-row items-center h-full ml-auto lg:hidden text-small">
            <li className={listItemStyle}>
                <Link href={"/sok"} className="flex flex-col items-center gap-1 no-underline">
                    <Search size="22" />
                    <div>Sök</div>
                </Link>
            </li>
            {showLoginButton && (
                <li className={listItemStyle + "bg-cherryRed text-white"}>
                    <Link
                        href={"https://app.movestic.se/privat/login"}
                        className="flex flex-col items-center gap-1 no-underline"
                    >
                        <Lock size="22" color="white" />
                        <div>Logga in</div>
                    </Link>
                </li>
            )}
            <li className={listItemStyle}>
                <Sheet open={open} onOpenChange={setOpen}>
                    <SheetTrigger asChild>
                        <div className="flex flex-col items-center gap-1">
                            <MenuToggle open={open} setOpen={setOpen} />
                            <div>Meny</div>
                        </div>
                    </SheetTrigger>
                    <SheetContent side={"right"} className="w-screen h-full mt-[4.5rem] bg-white">
                        <div className={`flex w-[200vw] relative transition-all ${linkMenuOpen ? "-ml-[100vw]" : ""}`}>
                            <FirstSheet setLinkMenuOpen={setLinkMenuOpen} setOpen={setOpen} navigation={navigation} />
                            <SecondSheet
                                linkMenuOpen={linkMenuOpen}
                                setLinkMenuOpen={setLinkMenuOpen}
                                setOpen={setOpen}
                                navigation={navigation}
                            />
                        </div>
                    </SheetContent>
                </Sheet>
            </li>
        </ol>
    );
}

function ContentMenuLinkMobile({
    linkUrl,
    linkTitle,
    onClick,
}: {
    linkUrl: string;
    linkTitle: string;
    onClick?: () => void;
}) {
    return (
        <li key={`tertrary_${linkUrl}`} onClick={onClick} tabIndex={0} role="menuitem">
            <Link
                href={linkUrl}
                className="no-underline text-cherryDark hover:cherryRed hover:underline focus:underline"
            >
                {linkTitle}
            </Link>
        </li>
    );
}

function MenuToggle({ open, setOpen }: { open: boolean; setOpen: (open: boolean) => void }) {
    const genericHamburgerLine = `h-0.5 w-6 mb-1 rounded-full bg-lightBlack transition ease transform duration-300`;
    return (
        <button
            className="h-[22px] flex flex-col items-center justify-center rounded group"
            onClick={() => setOpen(!open)}
            aria-haspopup="true"
            aria-controls="menu"
            aria-label="Öppna navigationsmeny"
        >
            <div className={`${genericHamburgerLine} ${open ? "rotate-45 translate-y-1.5 " : ""}`} />
            <div className={`${genericHamburgerLine} ${open ? "opacity-0" : ""}`} />
            <div className={`${genericHamburgerLine} ${open ? "-rotate-45 -translate-y-1.5 " : ""}`} />
        </button>
    );
}

function SecondSheet({ linkMenuOpen, setLinkMenuOpen, setOpen, navigation }) {
    const primaryLinkTitle = navigation.find((item) => item.childItems.find((x) => x._uid === linkMenuOpen))?.linkTitle;

    return (
        <div className="w-screen p-4">
            <div onClick={() => setLinkMenuOpen(false)} className="flex gap-2 py-4 text-cherryDark">
                <ArrowRight style={{ transform: "rotate(180deg)" }} />
                <strong> {primaryLinkTitle ?? "Tillbaka"}</strong>
            </div>
            {navigation.map((primaryItem) => {
                return primaryItem.childItems?.map((secondaryItem: NavigationitemComponent) => {
                    const url = secondaryItem.navigationLink && getLink(secondaryItem.navigationLink);

                    return (
                        <div
                            key={`sec_${secondaryItem._uid}`}
                            className={`pt-4 ${linkMenuOpen === secondaryItem._uid ? "block" : "hidden"}`}
                        >
                            <H4>
                                {url?.linkUrl ? (
                                    <Link
                                        href={url.linkUrl}
                                        className="flex gap-2 no-underline transition-all text-lightBlack hover:underline hover:gap-3 hover:-mr-1"
                                        onClick={() => {
                                            setLinkMenuOpen(false);
                                            setOpen(false);
                                        }}
                                    >
                                        {secondaryItem.linkTitle} <ArrowRight />
                                    </Link>
                                ) : (
                                    <div className="text-lightBlack">{secondaryItem.linkTitle}</div>
                                )}
                            </H4>
                            <ul className="flex flex-col gap-6 mt-6">
                                {secondaryItem.childItems?.map((item: NavigationitemComponent, ix) => {
                                    const lnk = getLink(item.navigationLink!);
                                    return (
                                        <ContentMenuLinkMobile
                                            key={`tert_${ix}`}
                                            onClick={() => {
                                                setLinkMenuOpen(false);
                                                setOpen(false);
                                            }}
                                            linkUrl={lnk.linkUrl}
                                            linkTitle={item.linkTitle!}
                                        />
                                    );
                                })}
                            </ul>
                        </div>
                    );
                });
            })}
        </div>
    );
}

function FirstSheet({ setLinkMenuOpen, setOpen, navigation }) {
    return (
        <Accordion type="single" collapsible className="w-screen">
            {navigation.map((primaryItem, index) => {
                const hasChildItems = primaryItem.childItems?.length > 0;
                const link = primaryItem.navigationLink && getLink(primaryItem.navigationLink);

                return hasChildItems ? (
                    <AccordionItem value={`item-${index}`} className="border-0" key={`item-${index}`}>
                        <AccordionTrigger className="p-4 font-bold no-underline border-b text-large border-ashGray focus:bg-brightGray">
                            {primaryItem.linkTitle}
                        </AccordionTrigger>
                        <AccordionContent>
                            <ul>
                                {primaryItem.childItems?.map(
                                    (secondaryItem: NavigationitemComponent, secondaryIndex) => {
                                        return (
                                            <li
                                                key={`secondary_${secondaryIndex}`}
                                                className="flex p-4 "
                                                onClick={() => setLinkMenuOpen(secondaryItem._uid!)}
                                            >
                                                {secondaryItem.linkTitle}
                                                <Chevron className="ml-auto -rotate-90" />
                                            </li>
                                        );
                                    },
                                )}
                            </ul>
                        </AccordionContent>
                    </AccordionItem>
                ) : (
                    <Link
                        href={link?.linkUrl}
                        className="block p-4 font-bold no-underline border-b text-large border-ashGray"
                        key={`item-${index}`}
                        onClick={() => {
                            setLinkMenuOpen(false);
                            setOpen(false);
                        }}
                    >
                        {primaryItem.linkTitle}
                    </Link>
                );
            })}
        </Accordion>
    );
}
